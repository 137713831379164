import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomerAction, removeCustomerAction } from 'store/reducers/customerReducer';
import { fetchCustomers } from 'actions/customer';
import Button from 'components/common/Button';
import Header from 'components/Header';

interface RootState {
  cash: { cash: number },
  customers: { customers: [] },
}

interface Customer {
  id: number,
  name: string,
}

function Counter() {
  const dispatch = useDispatch();
  const cash: number = useSelector((state: RootState) => state.cash.cash);
  const customers: [] = useSelector((state: RootState) => state.customers.customers);
  
  const addCash = (cash: number) => {
    dispatch({ type: 'ADD_CASH', payload: cash });
  };
  const getCash = (cash: number) => {
    dispatch({ type: 'GET_CASH', payload: cash });
  };
  
  const addCustomer = (name: string) => {
    const customer: Customer = {
      id: Date.now(),
      name,
    };
    dispatch(addCustomerAction(customer));
  };
  
  const removeCustomer = (id: number) => {
    dispatch(removeCustomerAction(id));
  };
  
  return (
    <div>
      <Header />
      <div className="d-flex flex-column align-items-start p-5 gap-3">
        <div>
          value: {cash}
        </div>
        <Button onClick={() => addCash(Number(prompt()))}>add cash</Button>
        <Button onClick={() => getCash(Number(prompt()))}>get cash</Button>
        <Button onClick={() => addCustomer(prompt() as string)}>add customer</Button>
        <Button onClick={() => dispatch(fetchCustomers())}>get customers from api</Button>
        {customers.length > 0
          ?
          <div>
            {customers.map(({ id, name }) =>
              <div key={id} onClick={() => removeCustomer(id)}>{name}</div>,
            )}
          </div>
          :
          <div className="mt-4">
            No customers
          </div>
        }
      </div>
    </div>
  );
}

export default Counter;
