import { addManyCustomersAction } from '../store/reducers/customerReducer';
import { Dispatch } from 'redux';

export const fetchCustomers = (): any => {
  return (dispatch: Dispatch) => {
    fetch('https://jsonplaceholder.typicode.com/users')
    .then(response => response.json())
    .then(json => dispatch(addManyCustomersAction(json)));
  };
};
