import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainPage from 'components/project/MainPage';
import PlaygroundEntryPage from 'components/project/playground/PlaygroundEntryPage';
import Counter from 'components/project/playground/сounter/Counter';
import UserList from 'components/project/playground/userList/UserList';
import TodoList from 'components/project/playground/todoList/TodoList';
import TaskTrackerPage from 'components/project/playground/taskTracker/TaskTrackerPage';
import Game from 'components/project/playground/ticTacToe/Toe';
import GridTry1 from 'components/project/playground/gridTry1/GridTry1';
import GridTry2 from 'components/project/playground/gridTry2/GridTry2';

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/playground" element={<PlaygroundEntryPage />} />
      <Route path="/playground/counter" element={<Counter />} />
      <Route path="/playground/user-list" element={<UserList />} />
      <Route path="/playground/todo-list" element={<TodoList />} />
      <Route path="/playground/task-tracker" element={<TaskTrackerPage />} />
      <Route path="/playground/tic-tac" element={<Game />} />
      <Route path="/playground/grid-test1" element={<GridTry1 />} />
      <Route path="/playground/grid-test2" element={<GridTry2 />} />
    </Routes>
  );
};

export default AppRouter;
