import React from 'react';
import Greeting from 'components/project/blocks/Greeting';

const MainPage = () => {
  return (
    <div className="d-flex flex-column">
      <Greeting />
    </div>
  );
};

export default MainPage;
