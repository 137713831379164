import React, { useEffect } from 'react';
import { userTypedSelector } from 'hooks/userTypedSelector';
import { useActions } from 'hooks/useActions';
import Header from 'components/Header';

const UserList: React.FC = () => {
  const { users, loading, error } = userTypedSelector(state => state.user);
  const { fetchUsers } = useActions();
  
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, []);
  
  if (error) {
    return <div><Header /><h2>{error}</h2></div>;
  }
  
  return (
    <div>
      <Header />
      {loading
        ? <h2>Загрузка</h2>
        : <div>
          <div className="pt-5">
            {users.map(({ id, name }) =>
              <div key={id}>{name}</div>,
            )}
          </div>
        </div>
      }
    </div>
  );
};

export default UserList;
