import { FaTimes } from 'react-icons/fa';

const Task = ({ task, onDelete, onToggle }) => {
  return (
    <div
      className={`list-group-item ${task.reminder && 'active'}`}
      onDoubleClick={() => onToggle(task.id)}
    >
      <h4 className="d-flex justify-content-between">
        {task.text}
        <FaTimes
          style={{ color: 'red', cursor: 'pointer' }}
          onClick={() => onDelete(task.id)}
        />
      </h4>
      <p>{task.day}</p>
    </div>
  );
};

export default Task;
