import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';

const SimpleButton = styled.button`
  color: #6f555c;
  font-size: 1em;
  margin: 0.75em;
  padding: 0.25em 1em;
  border: 2px solid #6f555c;
  border-radius: 3px;
`;


const Button: React.FC<{ children: any, onClick: MouseEventHandler }> = ({ children, onClick }) => {
  return (
    <div>
      <SimpleButton onClick={onClick}>
        {children}
      </SimpleButton>
    </div>
  );
};

export default Button;
