import React from 'react';
import Header from 'components/Header';

const PlaygroundEntryPage = () => {
  return (
    <Header />
  );
};

export default PlaygroundEntryPage;
