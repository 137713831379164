const defaultState = { customers: [] };

const ADD_MANY_CUSTOMERS = 'ADD_MANY_CUSTOMERS';
const ADD_CUSTOMER = 'ADD_CUSTOMER';
const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER';

export const customerReducer = (state = defaultState, action: { type: string; payload: any; }) => {
  switch (action.type) {
    case ADD_MANY_CUSTOMERS:
      return { ...state, customers: [...state.customers, ...action.payload] };
    case ADD_CUSTOMER:
      return { ...state, customers: [...state.customers, action.payload] };
    case REMOVE_CUSTOMER:
      return { ...state, customers: state.customers.filter(({ id }) => id !== action.payload) };
    default:
      return state;
  }
};

export const addManyCustomersAction = (payload: any) => ({ type: ADD_MANY_CUSTOMERS, payload });
export const addCustomerAction = (payload: any) => ({ type: ADD_CUSTOMER, payload });
export const removeCustomerAction = (payload: any) => ({ type: REMOVE_CUSTOMER, payload });
