import React, { useEffect } from 'react';
import { userTypedSelector } from 'hooks/userTypedSelector';
import { useActions } from 'hooks/useActions';
import Header from 'components/Header';

const TodoList: React.FC = () => {
  const { todos, error, loading, page, limit } = userTypedSelector(state => state.todo);
  const { fetchTodos, setTodoPage } = useActions();
  const pages = [1, 2, 3, 4, 5];
  
  useEffect(() => {
    fetchTodos(page, limit);
    // eslint-disable-next-line
  }, [page]);
  
  if (error) {
    return <div><Header /><h2>{error}</h2></div>;
  }
  
  return (
    <div>
      <Header />
      {loading
        ? <h2>Загрузка</h2>
        : <div>
          <div className="pt-5">
            {todos.map(todo =>
              <div key={todo.id}>
                {todo.id} - {todo.title}
              </div>,
            )}
          </div>
          <div className="d-flex">
            {pages.map(p =>
              <div
                onClick={() => setTodoPage(p)}
                key={p}
                style={{ border: p === page ? '2px solid green' : '2px solid gray', padding: 10 }}
              >
                {p}
              </div>,
            )}
          </div>
        </div>
      }
    
    </div>
  );
};

export default TodoList;
