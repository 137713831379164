const TaskHeader = ({ onAdd, showAdd }) => {
  return (
    <div className="d-flex justify-content-between align-items-center mb-4">
      <h2 className="mb-0">Task Tracker</h2>
      <button
        className={`btn btn-${showAdd ? 'danger' : 'success'}`}
        onClick={onAdd}
      >
        {showAdd ? 'Close' : 'Add'}
      </button>
    </div>
  );
};

export default TaskHeader;
