import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="d-flex justify-content-between align-items-center bg-light">
      <h2 className="my-2 mx-4">react playground</h2>
      <div className="d-flex justify-content-around gap-3 me-3">
        <Link to="/playground/">
          Главная страница
        </Link>
        <Link to="/playground/counter">
          Счетчики redux
        </Link>
        <Link to="/playground/user-list">
          UserList (TS lesson)
        </Link>
        <Link to="/playground/todo-list">
          TodoList (TS lesson)
        </Link>
        <Link to="/playground/task-tracker">
          Task tracker
        </Link>
        <Link to="/playground/tic-tac">
          Крестики-нолики
        </Link>
        <Link to="/playground/grid-test1">
          Grid Sample 1
        </Link>
        <Link to="/playground/grid-test2">
          Grid Sample 2
        </Link>
      </div>
    </header>
  );
};

export default Header;
