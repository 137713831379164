import { createStore, combineReducers, applyMiddleware } from 'redux';
import { cashReducer } from 'store/reducers/cashReducer';
import { customerReducer } from 'store/reducers/customerReducer';
import { todoReducer } from './reducers/todoReducer';
import { userReducer } from 'store/reducers/userReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
  cash: cashReducer,
  customers: customerReducer,
  todo: todoReducer,
  user: userReducer,
});

export type RootState = ReturnType<typeof rootReducer>

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
