import 'components/project/playground/gridTry1/GridTry1.css';
import Header from 'components/Header';

const GridTry1 = () => {
  
  // thisImg.addEventListener('mouseenter', function(e) {
  //   const w = e.target.clientWidth || e.target.offsetWidth || e.target.scrollWidth;
  //   e.target.style.transform = 'perspective(' + w * 3 + 'px)';
  //   processEnter(e, _thisImg);
  // });
  //
  // thisImg.addEventListener('touchstart', function(e) {
  //   win.preventScroll = true;
  //   const w = e.target.clientWidth || e.target.offsetWidth || e.target.scrollWidth;
  //   e.target.style.transform = 'perspective(' + w * 3 + 'px)';
  //   processEnter(e, _thisImg);
  // });
  
  return (
    <div>
      <Header />
      <div className="wrapper2">
        <div className="box box1">Box 1</div>
        <div className="box box2">Box 2</div>
        <div className="box box3">Box 3</div>
        <div className="box box4">Box 4</div>
      </div>
    </div>
  );
};

export default GridTry1;
