import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import AppRouter from 'routes/AppRouter';
import Footer from 'components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'index.css';

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <div className="content">
          <AppRouter />
        </div>
        <Footer />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
  );
};

render();
