import { useState } from 'react';

const AddTaskForm = ({ onAdd }) => {
  const [text, setText] = useState('');
  const [day, setDay] = useState('');
  const [reminder, setReminder] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!text) {
      alert('please add text');
      return;
    }

    onAdd({ text, day, reminder });
    setText('');
    setDay('');
    setReminder(false);
  };

  return (
    <form onSubmit={onSubmit} className="my-4">
      <div className="mb-3">
        <label>
          Task
          <input
            className="form-control"
            type="text"
            placeholder="Add task name"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </label>
      </div>
      <div className="mb-3">
        <label>
          Day & Time
          <input
            className="form-control"
            type="date"
            placeholder="Add day and time"
            value={day}
            onChange={(e) => setDay(e.target.value)}
          />
        </label>
      </div>
      <div className="mb-3 form-check">
        <label className="form-check-label" for="reminder">
          set reminder
        </label>
        <input
          className="form-check-input"
          id="reminder"
          type="checkbox"
          checked={reminder}
          value={reminder}
          onChange={(e) => setReminder(e.currentTarget.checked)}
        />
      </div>
      <input type="submit" value="Save Task" className="btn btn-dark" />
    </form>
  );
};

export default AddTaskForm;
