import { useEffect, useState } from 'react';
import Header from 'components/Header';
import TaskHeader from 'components/project/playground/taskTracker/items/TaskHeader';
import AddTaskForm from 'components/project/playground/taskTracker/items/AddTaskForm';
import Tasks from 'components/project/playground/taskTracker/items/Tasks';

const TaskTrackerPage = () => {
  const [showAddTask, setShowAddTask] = useState(false);
  const [tasks, setTasks] = useState([]);
  
  useEffect(() => {
    const getTasks = async () => {
      const tasksFromServer = await fetchTasks();
      setTasks(tasksFromServer);
    };
    
    getTasks();
  }, []);
  
  const fetchTasks = async () => {
    try {
      const response = await fetch('http://localhost:5000/tasks');
      return await response.json();
    } catch (e) {
      return [];
    }
  };
  
  const fetchTask = async (id) => {
    const response = await fetch(`http://localhost:5000/tasks/${id}`);
    
    return await response.json();
  };
  
  const addTask = async (task) => {
    const response = await fetch('http://localhost:5000/tasks', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(task),
    });
    
    const data = await response.json();
    
    setTasks([...tasks, data]);
  };
  
  const deleteTask = async (id) => {
    await fetch(`http://localhost:5000/tasks/${id}`, { method: 'DELETE' });
    setTasks(tasks.filter((task) => task.id !== id));
  };
  
  const toggleReminder = async (id) => {
    const taskToToggle = await fetchTask(id);
    const updateTask = { ...taskToToggle, reminder: !taskToToggle.reminder };
    
    const response = await fetch(`http://localhost:5000/tasks/${id}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(updateTask),
    });
    
    const data = await response.json();
    
    setTasks(
      tasks.map((task) =>
        task.id === id ? { ...task, reminder: data.reminder } : task,
      ),
    );
  };
  
  return (
    <div className="content">
      <Header />
      <div className="col-6 offset-3 col-xl-4 offset-4 card card-body">
        <TaskHeader
          onAdd={() => setShowAddTask(!showAddTask)}
          showAdd={showAddTask}
        />
        {showAddTask && <AddTaskForm onAdd={addTask} />}
        {tasks.length > 0 ? (
          <Tasks tasks={tasks} onDelete={deleteTask} onToggle={toggleReminder} />
        ) : (
          'No tasks'
        )}
      </div>
    </div>
  );
};

export default TaskTrackerPage;
