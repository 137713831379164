import React from 'react';
import avatar from 'assets/images/avatar.png';
import instagramLogo from 'assets/images/interface/social/instagram.png';
import styled from 'styled-components';

const Avatar = styled.img`
  width: 100%;
  border-radius: 50%;
  border: 3px solid #974ce9;
  box-shadow: -30px 15px 50px 0 rgba(21, 21, 21, 0.5);
  margin: 30px 0;
  transform: perspective(1500px) rotateY(10deg);
  transition: all 1s ease 0s;

  :hover {
    transform: perspective(3000px) rotateY(0deg);
    box-shadow: 0 15px 50px 0 rgba(21, 21, 21, 0.5);
  }
`;

const Heading = styled.h1`
  padding: 30px;
  margin: 30px 0;
  box-shadow: -10px 6px 30px 0 rgba(21, 21, 21, 0.4);
  border-radius: 15px;
  background-color: #feabe1;
`;

const Description = styled.div`
  padding: 30px;
  margin: 30px 0;
  box-shadow: -10px 6px 30px 0 rgba(21, 21, 21, 0.4);
  border-radius: 15px;
  background-color: #eee;

  p {
    margin-bottom: 30px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`;

const Name = styled.span`
  background-color: #974ce9;
  padding: 5px 10px;
  margin: 0 -10px;
`;

const LinkBtn = styled.a`
  color: white;
  background-color: #974ce9;
  padding: 5px 10px;
  border: none;
  box-shadow: -6px 6px 5px 0 rgba(21, 21, 21, 0.5);
  font-size: 1.5em;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s;

  :hover {
    color: white;
    background-color: #8535e3;
    transform: translate(-5px, 5px);
    box-shadow: -2px 2px 2px 0 rgba(21, 21, 21, 0.5);
  }
`;

const Greeting = () => {
  return (
    <div className="container pt-5">
      <div className="row">
        <div className="col-8 offset-2 col-lg-6 offset-lg-0">
          <Avatar id="avatar" src={avatar} alt="image" />
        </div>
        <div className="col-10 offset-1 col-lg-6 offset-lg-0">
          <Heading>
            Привет! Меня&nbsp;зовут <Name>Настя&nbsp;Мурпэйнтер,</Name> и я&nbsp;влюбляю людей в&nbsp;татуировки.
          </Heading>
          <Description>
            <p>
              Татуировка должна украшать, подчеркивать достоинства твоего тела, поэтому я очень внимательно отношусь к выбору
              места, размера и сюжета татуировки.
              Каждый эскиз рисую специально для тебя, учитывая все пожелания. Если ты любишь нежные, но яркие татуировки - мы
              сработаемся!
            </p>
            <p>
              Для меня важен коннект между людьми, потому что когда он случается - на свет появляются самые чудесные татуировки.
              А еще на моих сеансах всегда лампово, уютно и совсем не страшно! 🙂
              Моя стилистика - это графические работы с добавлением акцентного (чаще всего красного) цвета. Люблю рисовать
              цветочные
              композиции, животных и какие-нибудь безумные идеи! Мой секрет контрастных работ - четкий, но тонкий контур и
              нежные
              воздушные тени. Благодаря этому сочетанию работы заживают ярко и аккуратно. Всегда рада общению и с удовольствием
              нарисую для тебя эскиз мечты!
            </p>
            <p>
              <LinkBtn
                href="https://www.instagram.com/murpainter"
                target="_blank"
                className="d-flex align-items-center gap-3 justify-content-center flex-wrap mt-4"
              >
                <img
                  src={instagramLogo}
                  className="me-2 d-none d-md-block"
                  style={{ width: 30, height: 30 }}
                  alt="instagram"
                />
                Напиши&nbsp;мне!
              </LinkBtn>
            </p>
          </Description>
        </div>
      </div>
    </div>
  );
};

export default Greeting;
