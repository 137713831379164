import React from 'react';

const Footer = () => {
  return (
    <footer className="text-center py-4" style={{ backgroundColor: '#eeeeee', color: '#222222' }}>
      murpainter &copy; {new Date().getFullYear()}
    </footer>
  );
};

export default Footer;
