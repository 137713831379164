import 'components/project/playground/gridTry2/GridTry2.css';
import Header from 'components/Header';

const GridTry2 = () => {
  return (
    <div>
      <Header/>
      <div className="wrapper">
        <div>
          Lorem ipsum dolor sit amet
        </div>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium blanditiis cupiditate deleniti labore laudantium
          nulla quidem recusandae repellendus sed vel? Aliquam neque quod repellendus temporibus velit. Corporis eaque eligendi
          expedita facere laboriosam magni, nostrum suscipit velit vitae voluptates. Corporis, qui!
        </div>
        <div className="nested">
          <div>Lorem</div>
          <div>Lorem</div>
          <div>Lorem</div>
          <div>Lorem</div>
          <div>Lorem</div>
          <div>Lorem</div>
          <div>Lorem</div>
        </div>
        <div>Lorem ipsum dolor sit amet</div>
        <div>Lorem ipsum dolor sit amet</div>
        <div>Lorem ipsum dolor sit amet</div>
      </div>
    </div>
  );
};

export default GridTry2;
