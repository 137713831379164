import { UserAction, UserActionTypes } from 'types/user';
import { Dispatch } from 'redux';
import axios, { AxiosResponse } from 'axios';


export const fetchUsers = () => {
  return async (dispatch: Dispatch<UserAction>) => {
    try {
      dispatch({ type: UserActionTypes.FETCH_USERS });
      const response: AxiosResponse<any> = await axios.get('https://jsonplaceholder.typicode.com/users');
      setTimeout(() => {
        dispatch({ type: UserActionTypes.FETCH_USERS_SUCCESS, payload: response.data });
      }, 500);
    } catch (e) {
      dispatch({
        type: UserActionTypes.FETCH_USERS_ERROR,
        payload: 'Ошибка',
      });
    }
  };
};
